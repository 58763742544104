import { Popup } from 'Lib/popup/popup';

export class PopupVideo extends Popup {
    constructor(selector = 'popup', elPopup) {
        super(selector, elPopup);
    }

    close() {
        super.close();
        this._modalBody.innerHTML = '';
    }

    open(type, params) {
        if (type === 'vk') {
            this._iframe = `<iframe src="https://vk.com/video_ext.php?${params}" allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameborder="0" allowfullscreen></iframe>`;
        } else if (type === 'youtube') {
            this._iframe = `<iframe src="https://www.youtube.com/embed/${params}?autoplay=0&amp;autohide=1&amp;fs=1&amp;rel=0&amp;hd=1&amp;wmode=transparent&amp;enablejsapi=1&amp;html5=1" style="min-height: 100%;"></iframe>`;
        } else if (type === 'rt') {
            this._iframe = `<iframe src="https://rutube.ru/play/embed/${params}" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen style="min-height: 100%;"></iframe>`;
        }

        this._modalBody.insertAdjacentHTML('beforeend', this._iframe);
        super.open();
    }

    popupVideoOpenDefault() {
        if (!document.querySelector('.popup_video')) {
            document.body.insertAdjacentHTML('beforeend', `
                <div class="popup popup_video">
                    <div class="popup__box">
                        <div class="popup__modal-body"></div>
                    </div>
                </div>
            `);
        }

        this._elsBtnVideo = document?.querySelectorAll('.js-popup-video');
        this._elBtnVideo = new PopupVideo('popup', document?.querySelector('.popup_video'));

        this._elsBtnVideo?.forEach((item) => {
            item.addEventListener('click', () => {
                if (item.dataset?.type && item.dataset?.params) {
                    this._elBtnVideo.open(item.dataset.type, item.dataset.params);
                }
            });
        });
    }
}